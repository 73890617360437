/**
 * create by zhangxiang on 2023-06-10 12:50
 * 类注释：
 * 备注：
 */
import {defineStore} from 'pinia'

export const useUserStore = defineStore('user-store', {
    state: () => {
        return {
            needLogin: false, // 问题答案,
            isLogin: false
        }
    },
    getters: {
        getNeedLogin: (state) => {
            return state.needLogin
        },
        getIsLogin: (state) => {
            return state.isLogin
        },
    },
    actions: {
        setNeedLogin(val) {
            this.needLogin = val
        },
        setIsLogin(val) {
            this.isLogin = val
        },
    },
})
