/**
 * create by zhangxiang on 2023-06-06 10:23
 * 类注释：
 * 备注：
 */

// // 这里是 ./svg是路径
// const webpackContext = require.context("./svg", true, /\.svg$/);
// const requireAll = (requireContext) => {
//     // requireContext.keys() 匹配的 文件路径数组
//     return requireContext.keys().map(requireContext);
// };
// // 得到一个完整解析的module数组
// requireAll(webpackContext);

const req = require.context('./svg', true, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

// import {createApp} from 'vue'
// import SvgIcon from '../components/SvgIcon'// svg component
//
// // register globally
// createApp().component('svg-icon', SvgIcon)
//
// const req = require.context('./svg', true, /\.svg$/)
// const requireAll = requireContext => requireContext.keys().map(requireContext)
// requireAll(req)


