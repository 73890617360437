import {createRouter, createWebHistory} from 'vue-router'

export const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/index'),
        meta: {
            title: 'index'
        }
    },
    {
        path: '/list',
        name: 'index',
        component: () => import('@/views/home'),
        children: [
            {
                path: '/list',
                name: 'list',
                component: () => import('@/views/home/List'),
                meta: {
                    title: 'home',
                }
            },
            {
                path: '/detail',
                name: 'detail',
                component: () => import('@/views/home/detail'),
                meta: {
                    title: 'home',
                }
            }
        ]
    },
    {
        path: '/model',
        name: 'model',
        component: () => import('@/views/model'),
        meta: {
            title: 'model'
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.afterEach((to) => {  // 一定要再afterEach中判断而不是beforeEach，因为beforeEach在点击返回之后获取到的值不准确，每返回一次，会获取到延后一次的to、history
    if (window.history.state && window.history.state.forward) { // 或者判断 to.forward,window.history.state.forward是vue-router写入的，当返回或前进的时候才会有值
        to.meta.isBack = true;
    } else {
        to.meta.isBack = false;
    }
});

export default router
