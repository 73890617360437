/**
* create by zx on 2022/6/14 16:36
* 类注释：
* 备注：
*/
<template>
  <div class="header">
    <svg-icon icon-class="logo-blue" class="logo" @click="toIndex"></svg-icon>
    <div style="flex: 1"></div>
    <div class="btn" :class="{'select':route.meta.title==='index'}" @click="toIndex">
      <svg-icon :icon-class="route.meta.title==='index'?'Frame_114':'Frame_115'"
                style="width: 18px;height: 18px"></svg-icon>
      介绍
    </div>
    <div class="btn" :class="{'select':route.meta.title==='home'}" @click="toHome">
      <svg-icon :icon-class="route.meta.title==='home'?'Frame-12':'Frame-11'"
                style="width: 18px;height: 18px"></svg-icon>
      问题
    </div>
    <div class="btn" :class="{'select':route.meta.title==='model'}" @click="toModel">
      <svg-icon :icon-class="route.meta.title==='model'?'Frame-10':'Frame-9'"
                style="width: 18px;height: 18px"></svg-icon>
      模型
    </div>
    <div class="btn" @click="toLogin">
      <svg-icon icon-class="Frame-7" style="width: 18px;height: 18px"></svg-icon>
      <el-dropdown ref="dropdown1" trigger="click" v-if="hasLogin">
        <span @click="dropdown1.handleOpen()"> 已登录</span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="loginOut">登出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <span v-else>登录</span>
    </div>
    <Login ref="login"></Login>
  </div>
</template>

<script setup>
import {ref, computed, watch} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import Login from '@/views/login/index'
import {useUserStore} from '@/store'
import {ElMessageBox} from "element-plus";
import {logout} from "@/http/api/user";
import {clearSession, getSession} from "@/utils";

const router = useRouter()
const route = useRoute()
const login = ref(null)
const userStore = useUserStore()
const dropdown1 = ref(null)

const hasLogin = computed(() => userStore.getIsLogin)

const toHome = () => {
  router.push({path: '/list', query: {cId: JSON.stringify([])}})
}
const toIndex = () => {
  router.push({path: '/'})
}

const needLogin = computed(() => {
  return userStore.getNeedLogin
})


const loginOut = () => {
  ElMessageBox.confirm(
      '确认退出？',
      "退出",
      {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      }).then(() => {
    logout({
      atomechoSession: getSession(),
    }).then(() => {
      clearSession()
      userStore.setIsLogin(false)
      router.push({path: '/'})
    })
  })
}

watch(needLogin, (val) => {
  if (val) {
    login.value.showDialog = true
    userStore.setNeedLogin(false)
  }
})

const toModel = () => {
  router.push({path: '/model'})
}

const toLogin = () => {
  if (!hasLogin.value)
    login.value.showDialog = true
}

</script>

<style scoped lang="less" rel="stylesheet/less">
.header {
  width: 100%;
  display: flex;
  height: 60px;
  position: sticky;
  background: white;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.07);

  .logo {
    width: 160px;
    height: 34px;
    margin-top: 13px;
    margin-left: 30px;
    cursor: pointer;
  }

  .btn {
    cursor: pointer;
    border-radius: 5px;
    padding: 0 10px;
    margin-top: 15px;
    margin-right: 20px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
  }

  .select {
    color: #3361FF;
  }

}

:deep( .el-dropdown) {
  line-height: 30px !important;
  font-size: 16px;
  color: #2D384E;
}

:deep(.el-dropdown-menu__item) {
  font-size: 16px;
  padding: 5px 20px;
}
</style>