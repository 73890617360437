/**
* create by zhangxiang on 2023-05-25 13:12
* 类注释：
* 备注：
*/
<template>
  <el-dialog
      append-to-body
      v-model="showDialog"
      class="login"
      width="450px"
      :close-on-click-modal="false">
    <div style="text-align: center">
      <svg-icon icon-class="logo-blue" class="logo" style="width: 200px;
  height: 48px;"></svg-icon>
      <div style="font-weight: 600;
            font-size: 20px;
           margin-top: 5px;
           margin-bottom: 20px;
            letter-spacing: 0.06em;">
        欢迎登录
      </div>
    </div>
    <el-form ref="ruleFormRef" :rules="rules" style="padding: 0 20px" :model="formData">
      <el-form-item prop="phone">
        <el-input placeholder="请输入手机号" v-model="formData.phone"></el-input>
      </el-form-item>
      <el-form-item prop="identity_code">
        <div style="display: flex;width: 100%">
          <div style="flex: 15">
            <el-input placeholder="请输入验证码" v-model="formData.identity_code"></el-input>
          </div>
          <div style="flex: 9;margin-left: 20px">
            <div style="text-align: right">
              <el-button
                  style="color: #666;width: 100%"
                  :disabled="loading"
                  @click="getCode">
                {{ getCodeBtn }}
              </el-button>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 100%;background: #3467FF;color: white;" @click="toLogin">登录</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import {reactive, toRefs, defineExpose, ref} from 'vue'
import {login, smscode} from "@/http/api/user";
import {ElMessage} from 'element-plus'
import {setSession} from "@/utils";
import {useUserStore} from '@/store'

const userStore = useUserStore()

const ruleFormRef = ref(null)

const data = reactive({
  formData: {},
  loading: false,
  showDialog: false,
  getCodeBtn: '获取验证码',
  codeSecond: 60,
  timer: null
})

const checkPhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('手机号不能为空'));
  } else {
    const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的手机号'));
    }
  }
};

const rules = reactive({
  phone: [
    {required: true, message: '请输入手机号', trigger: 'blur'},
    {validator: checkPhone, trigger: 'blur'}
  ],
  identity_code: [
    {required: true, message: '请输入验证码', trigger: 'blur'},
  ]
})
let timer = null

const getCode = () => {
  ruleFormRef.value.validateField(["phone"], isOk => {
    if (isOk) {
      data.loading = true
      timer = setInterval(() => {
        if (data.codeSecond > 0) {
          data.getCodeBtn = data.codeSecond--
        } else {
          data.getCodeBtn = '获取验证码'
          data.codeSecond = 60
          data.loading = false
          window.clearInterval(timer)
        }
      }, 1000)
      smscode({
        phone: data.formData.phone
      }).then(() => {
        ElMessage({
          message: '验证码发送成功',
          type: 'success',
        })
      })
    }
  })
}
const toLogin = () => {
  ruleFormRef.value.validate(isOk => {
    if (isOk) {
      login({
        phone: data.formData.phone,
        smsCode: data.formData.identity_code
      }).then(res => {
        setSession(res.data.atomecho_session)
        userStore.setIsLogin(true)
        data.showDialog = false
        ElMessage({
          message: '登录成功',
          type: 'success',
        })
      }).catch(err => {
        console.log(err)
      })
    }
  })
}


const {formData, showDialog, loading, getCodeBtn} = toRefs(data)

defineExpose({
  showDialog
})
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  border-radius: 10px;
}

/deep/ .el-dialog__body {
  padding: 15px 20px
}

.logo {
  width: 200px;
  height: 48px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

/deep/ .el-overlay {
  z-index: 7000 !important;
}

/deep/ .el-dialog {
  border-radius: 10px;
}

/deep/ .el-dialog__body {
  padding: 15px 20px
}
</style>