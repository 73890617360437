import axios from 'axios'
import {ElMessage} from 'element-plus'
import {store} from '@/store'
import {useUserStore} from "@/store";
import {clearSession} from "@/utils";

const userStore = useUserStore(store)

const Http = axios.create({
    baseURL: '/userInfo',
    timeout: 60 * 1000,
    withCredentials: true
})

Http.interceptors.request.use(config => {
    if (localStorage.getItem('cookie')) {
        config.headers['cookie'] = localStorage.getItem('cookie')
    }
    return config
})

Http.interceptors.response.use(response => {
    const data = response.data
    if (data.errNo === 0) {
        return data
    } else if (data.errNo === 3) {
        ElMessage.error(data.errMsg)
        clearSession()
        userStore.setIsLogin(false)
    } else {
        ElMessage.error(data.errMsg)
        return Promise.reject(data)
    }
}, error => {
    return Promise.reject(error)
})

export default Http
