/**
 * create by zhangxiang on 2023-06-14 10:41
 * 类注释：
 * 备注：
 */

import Http from "@/http/user";

/**
 * 登录接口
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const login = (data) => {
    return Http.post('/user/login', data)
}

/**
 * 验证码接口
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const smscode = (data) => {
    return Http.post('/user/send-smscode', data)
}

/**
 * 验证码接口
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const logout = (data) => {
    return Http.post('/user/logout', data)
}


/**
 * 验证码接口
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const checkLogin = (data) => {
    return Http.post('/user/check-login', data)
}
