import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import './assets/css/index.css'


const app = createApp(App)
app.use(ElementPlus, {
    locale: zhCn,
    size: 'large'
})

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import "@/icons/index"; // 将src/icons/index.js引入
import SvgIcon from "./components/SvgIcon"; // 引入SvgIcon.vue
import {setupStore} from './store'

setupStore(app)
app.use(router)
app.use(mavonEditor)
app.component("svg-icon", SvgIcon)
app.mount('#app')
