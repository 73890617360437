<template>
  <div class="app">
    <Header></Header>
    <router-view></router-view>
  </div>
</template>

<script setup>
import Header from '@/components/Header'
import {useUserStore} from '@/store'
import Cookies from "js-cookie";

const userStore = useUserStore()

userStore.setIsLogin(!!Cookies.get('atomecho_session'))
</script>

<style scoped lang="less" rel="stylesheet/less">
.app {
  background: #fff;
  height: 100vh;
}
</style>
