/**
 * create by zhangxiang on 2023-06-09 11:32
 * 类注释：
 * 备注：
 */


import Cookies from 'js-cookie'

export const isLogin = () => {
    return !!Cookies.get('atomecho_session')
}

export function setSession(token) {
    return Cookies.set("atomecho_session", token, {domain: "atomecho.cn", expires: 7})
}

export function getSession() {
    return Cookies.get('atomecho_session')
}

export function clearSession() {
    Cookies.remove('atomecho_session', {domain: "atomecho.cn", expires: 7})
    clearAllCookie()
}

function clearAllCookie() {
    var keys = document.cookie.match(/[^ =;]+(?==)/g);
    if (keys) {
        for (var i = keys.length; i--;)
            document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
    }
}


export const getModelName = (aswer) => {
    switch (aswer.modelType) {
        case 'tongyi':
            return '萝卜'
        case 'mianbi':
            return '南瓜'
        case 'chatglm':
            return '辣椒'
        case 'chatgpt':
            return '土豆'
        case 'xinghuo':
            return '胡萝卜'
        case 'yiyan':
            return '茄子'
        default:
            return ''
    }
}

export const getModelIcon = (aswer) => {
    switch (aswer.modelType) {
        case 'tongyi':
            return 'qianwen-Radish'
        case 'mianbi':
            return 'mianbi-Pumpkin'
        case 'chatglm':
            return 'ChatGLM-Chili'
        case 'chatgpt':
            return 'ChatGPT-Potato'
        case 'xinghuo':
            return 'xinghuo-Carrot'
        case 'yiyan':
            return 'yiyan-Eggplant'
        default:
            return ''
    }
}


